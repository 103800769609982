import React from "react";
import styles from "./FooterIcons.module.css";

const FooterIconFile = () => {
  return (
    <div style={{ marginTop: "6px" }}>
      <ul className={styles.socialmedia}>
        <li>
          {/* href="navigationpath" */}
          <a>
            <svg
              viewBox="0 0 10.712 20"
              height="20"
              width="10.712"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                transform="translate(-22.89)"
                d="M32.9,11.25l.555-3.62H29.982V5.282a1.81,1.81,0,0,1,2.041-1.955H33.6V.245A19.255,19.255,0,0,0,30.8,0c-2.86,0-4.73,1.734-4.73,4.872V7.63H22.89v3.62h3.179V20h3.913V11.25Z"
                id="facebook"
              ></path>
            </svg>
          </a>
        </li>
        <li>
          <a>
            <svg
              viewBox="0 0 20 20"
              height="20"
              width="20"
              xmlns="http://www.w3.org/2000/svg"
              id="instagram"
            >
              <g
                transform="translate(15.354 3.707)"
                data-name="Group 64"
                id="Group_64"
              >
                <g data-name="Group 63" id="Group_63">
                  <path
                    fill="#00"
                    transform="translate(-392.401 -94.739)"
                    d="M392.871,94.739a.47.47,0,1,0,.47.47A.47.47,0,0,0,392.871,94.739Z"
                    data-name="Path 5"
                    id="Path_5"
                  ></path>
                </g>
              </g>
              <g
                transform="translate(5.837 5.837)"
                data-name="Group 66"
                id="Group_66"
              >
                <g data-name="Group 65" id="Group_65">
                  <path
                    fill="#000"
                    transform="translate(-145.804 -145.804)"
                    d="M149.967,145.8a4.163,4.163,0,1,0,4.163,4.163A4.168,4.168,0,0,0,149.967,145.8Z"
                    data-name="Path 6"
                    id="Path_6"
                  ></path>
                </g>
              </g>
              <g data-name="Group 68" id="Group_68">
                <g data-name="Group 67" id="Group_67">
                  <path
                    fill="#000"
                    d="M14.517,0H5.483A5.489,5.489,0,0,0,0,5.483v9.035A5.489,5.489,0,0,0,5.483,20h9.035A5.489,5.489,0,0,0,20,14.517V5.483A5.489,5.489,0,0,0,14.517,0ZM10,15.486A5.486,5.486,0,1,1,15.486,10,5.492,5.492,0,0,1,10,15.486Zm5.814-9.633A1.667,1.667,0,1,1,17.48,4.186,1.669,1.669,0,0,1,15.814,5.853Z"
                    data-name="Path 7"
                    id="Path_7"
                  ></path>
                </g>
              </g>
            </svg>
          </a>
        </li>
        <li>
          <a>
            <svg
              viewBox="0 0 20 20"
              height="20"
              width="20"
              xmlns="http://www.w3.org/2000/svg"
              id="_x31_0.Linkedin"
            >
              <path
                fill="#000"
                transform="translate(-31.438 -29.201)"
                d="M51.438,49.2V41.755c0-3.659-.788-6.455-5.057-6.455a4.413,4.413,0,0,0-3.99,2.186h-.051V35.63H38.3V49.2h4.219V42.466c0-1.779.33-3.482,2.516-3.482,2.16,0,2.186,2.008,2.186,3.583v6.607h4.219Z"
                data-name="Path 1"
                id="Path_1"
              ></path>
              <path
                fill="#000"
                transform="translate(-10.97 -30.17)"
                d="M11.3,36.6h4.219V50.17H11.3Z"
                data-name="Path 2"
                id="Path_2"
              ></path>
              <path
                fill="#000"
                transform="translate(-10 -10)"
                d="M12.44,10a2.452,2.452,0,1,0,2.44,2.44A2.44,2.44,0,0,0,12.44,10Z"
                data-name="Path 3"
                id="Path_3"
              ></path>
            </svg>
          </a>
        </li>
        <li>
          <a>
            <svg
              viewBox="0 0 19.838 18.6"
              height="18.6"
              width="19.838"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                transform="translate(0 -15.988)"
                data-name="svgexport-10 (13)"
                id="svgexport-10_13_"
              >
                <g
                  transform="translate(0 15.988)"
                  data-name="Group 10"
                  id="Group_10"
                >
                  <g
                    transform="translate(0 0)"
                    data-name="Group 9"
                    id="Group_9"
                  >
                    <path
                      fill="#000"
                      transform="translate(0 -15.988)"
                      d="M19.449,29.877a5.73,5.73,0,0,1-3.9-3.269.188.188,0,0,0-.011-.022.82.82,0,0,1-.093-.675c.145-.34.7-.516,1.068-.632.107-.033.207-.066.29-.1a2.245,2.245,0,0,0,.791-.472.751.751,0,0,0,.241-.541.851.851,0,0,0-.637-.738,1.152,1.152,0,0,0-.432-.082.961.961,0,0,0-.4.082,2.255,2.255,0,0,1-.827.236.759.759,0,0,1-.274-.06c.007-.124.015-.252.024-.383l0-.057a9.821,9.821,0,0,0-.253-4.005,5.388,5.388,0,0,0-1.214-1.737,5.076,5.076,0,0,0-1.452-.957,5.737,5.737,0,0,0-2.265-.479h-.05l-.341,0a5.759,5.759,0,0,0-2.268.479A5.048,5.048,0,0,0,6,17.426,5.4,5.4,0,0,0,4.8,19.16a9.829,9.829,0,0,0-.254,4v0c.009.144.019.294.027.44a.8.8,0,0,1-.325.061,2.151,2.151,0,0,1-.884-.237.794.794,0,0,0-.336-.069,1.188,1.188,0,0,0-.594.166.822.822,0,0,0-.42.537.741.741,0,0,0,.283.7,2.438,2.438,0,0,0,.739.423c.084.033.185.064.29.1.367.117.924.293,1.069.632a.826.826,0,0,1-.094.675.176.176,0,0,0-.01.022,6.566,6.566,0,0,1-1,1.545,5.608,5.608,0,0,1-1.205,1.064,4.435,4.435,0,0,1-1.693.658A.463.463,0,0,0,0,30.359a.587.587,0,0,0,.048.2h0a1.239,1.239,0,0,0,.672.574,7.675,7.675,0,0,0,1.029.353,10.853,10.853,0,0,0,2.649.353,10.445,10.445,0,0,0,4.331-.941,6.609,6.609,0,0,0,1.016-.6A8.208,8.208,0,0,0,13.3,29.5a.52.52,0,0,1,.681-.114,10.621,10.621,0,0,0,3.268,1.331,6.312,6.312,0,0,0,1.264.127,1.242,1.242,0,0,0,1.216-1.042.485.485,0,0,0-.021-.248.459.459,0,0,0-.42-.313,4.411,4.411,0,0,1-2.84-1.42ZM15.9,17.45A8.962,8.962,0,0,1,16.25,20a10.044,10.044,0,0,1-.105,1.409c-.013.09-.026.18-.039.264a.25.25,0,0,1-.036.108.755.755,0,0,1-.243.059c-.091.011-.178.021-.268.031A11.819,11.819,0,0,1,10,21.848,11.774,11.774,0,0,1,3.441,21.87c-.09-.011-.177-.021-.268-.031A.755.755,0,0,1,2.93,21.78a.25.25,0,0,1-.036-.108c-.013-.085-.026-.174-.039-.264A10.046,10.046,0,0,1,2.75,20a8.959,8.959,0,0,1,.348-2.545,3.737,3.737,0,0,1,.96-1.6A3.687,3.687,0,0,1,5.65,14.9,8.37,8.37,0,0,1,10,14.669a8.37,8.37,0,0,1,4.35.229,3.687,3.687,0,0,1,1.592,1.354A3.734,3.734,0,0,1,15.9,17.45Z"
                      data-name="Path 8"
                      id="Path_8"
                    ></path>
                  </g>
                </g>
              </g>
            </svg>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default FooterIconFile;
